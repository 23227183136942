
    .header-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .button-space {
            margin-left: 10px;
        }
    }

    .iconfontSort {
        color: #409eff;
    }

    .roleNot2 {
        ::v-deep th {
            padding: 0 !important;
            height: 45px;
            line-height: 45px;
        }

        ::v-deep td {
            padding: 0 !important;
            height: 45px;
            line-height: 45px;
        }
    }

    ::v-deep .el-table--border::after, .el-table--group::after, .el-table::before {
        display: none;
    }

    ::v-deep .caret-wrapper .ascending {
        border-bottom-color: #14141C
    }

    ::v-deep .caret-wrapper .descending {
        border-top-color: #14141C
    }

    ::v-deep .pages-center {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }

    ::v-deep.el-select {
        width: 140px;
    }

    .look-detail {
        color: #0200D8;

        &:hover {
            color: #5864e4;
        }
    }

    .rankImg {
        background: url("./../../assets/images/dataCenter/one.png") no-repeat center center;
    }

    .rankImgTwo {
        background: url("./../../assets/images/dataCenter/two.png") no-repeat center center;
    }

    .rankImgThree {
        background: url("./../../assets/images/dataCenter/three.png") no-repeat center center;
    }

    .operation-detail {
        /*margin: 0 auto;*/
        width: 30px;
        height: 30px;
        display: inline-block;
        font-size: 18px;
        color: #ffffff;
        background: #0200D8;
        text-align: center;
        border-radius: 50%;
        line-height: 30px;
    }

    .scoreList {
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                padding: 30px;
            }
        }

        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        /*overflow: hidden;*/
        .scoreList-header {
            color: #14141C;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-right {
                color: #343442;

                i {
                    margin-left: 10px;
                    font-size: 14px;
                }
            }
        }
    }

    .box {
        .xlTable {
            height: calc(100vh - 300px);
            overflow-y: auto;
            border: 1px solid #EEEEEE;
            margin-top: 20px;
            font-size: 14px;

            .xlTableHeader {
                display: flex;
                height: 60px;
                background: #F3F4FA;
                line-height: 60px;
                color: #14141C;
                text-align: center;

                .xlTableHeaderItem {
                    //width: 25%;
                  width: 1%;
                  flex: 1;
                }
            }

            .xlTableBody {
                display: flex;

                .xlTableHeaderItem:nth-child(1) {
                    justify-content: left;

                    i {
                        margin-left: 30px;
                    }
                }

                .xlTableHeaderItem {
                    border-bottom: 1px solid #EEEEEE;
                    //width: 25%;
                    width: 1%;
                    flex: 1;
                    //height: 60px;
                    line-height: 20px;
                    padding: 20px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        font-size: 12px;
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
            }

            .xlTableFooter {
                display: flex;

                .xlTableFooterItem {
                    border-bottom: 1px solid #EEEEEE;
                    //width: 25%;
                    width: 1%;
                    flex: 1;
                    //height: 60px;
                    line-height: 20px;
                    padding: 20px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .xlTableFooterItem:nth-child(6) {
                    color: #1222D8;
                    cursor: pointer;
                }
            }
        }
    }
